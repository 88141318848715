<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">时间 : </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">区域 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.regionName"
                  placeholder="区域名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">订单编号 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.orderCode"
                  placeholder="订单编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10"
              >查询</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10"
              >重置</el-button>
            </div>

            <div class="marginLeft10 flex align-items marginRight10 marginBottom10">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
              >导出</el-button>
            </div>
          </div>
        </div>

        <div class="order-box">
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.totalPrice }}</h3>
            <div class="order-box-info">订单金额(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.depositPrice }}</h3>
            <div class="order-box-info">押金金额（元）</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.couponPrice }}</h3>
            <div class="order-box-info">优惠券扣减(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.memberPrice }}</h3>
            <div class="order-box-info">会员等级扣减(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.returnPrice }}</h3>
            <div class="order-box-info">退款金额(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.payPrice ? (orderData.payPrice - orderData.returnPrice - orderData.depositPrice ).toFixed(2) : 0  }}</h3>
            <div class="order-box-info">实付金额(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.depositPrice2 }}</h3>
            <div class="order-box-info">押金退款金额（元）</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.couponPayPrice }}</h3>
            <div class="order-box-info">优惠券售价(元)</div>
          </div>
        </div>
      </template>
      <template
        slot="payPrice"
        slot-scope="{ row }"
      >
        <div>{{  row.payPrice &&(row.payPrice - row.returnPrice - row.depositPrice ).toFixed(2)>0 ? (row.payPrice - row.returnPrice - row.depositPrice ).toFixed(2) : '0.00'   }}</div>
      </template>
    </avue-crud>

  </div>
</template>

<script>
import { financeList, financeCount } from "@/api/region/finance";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        createTime: "",
        orderCode: "",
        regionName: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "统计报表-财务",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "订单编号",
            prop: "code",
          },
          {
            label: "区域名称",
            prop: "regionName",
            width:100
          },
          {
            label: "车辆类型",
            prop: "carTypeName",
          },
          {
            label: "车辆编号",
            prop: "carCode",
            width:150
          },
          {
            label: "订单金额（元）",
            prop: "totalPrice",
            width:120
          },
          {
            label: "押金金额（元）",
            prop: "depositPrice",
            width:120
          },
          {
            label: "优惠券类型(获取方式)",
            prop: "couponWay",
            width:150
          },
          {
            label: "优惠券扣减（元）",
            prop: "couponPrice",
            width:120
          },
          {
            label: "会员等级扣减（元）",
            prop: "memberPrice",
            width:150
          },
          {
            label: "退款金额(元)",
            prop: "returnPrice",
            width:120
          },
          {
            label: "实付金额（元）",
            prop: "payPrice",
            width:120
          },
          {
            label: "押金退款金额（元）",
            prop: "depositPrice2",
            width:150
          },
          {
            label: "优惠券售价（元）",
            prop: "couponPayPrice",
            width:120
          },
        ],
      },
      form: {
        id: "",
        createTime: "",
      },
      orderData: {
        couponPayPrice: 0,
        couponPrice: 0,
        memberPrice: 0,
        payPrice: 0,
        returnPrice: 0,
        totalPrice: 0,
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = {
        orderCode: searchForm.searchData.orderCode,
        regionName: searchForm.searchData.regionName,
      };
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
    this.financeCount();
  },
  methods: {
    financeCount() {
      const searchData = {
        orderCode: this.searchData.orderCode,
        regionName: this.searchData.regionName,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      financeCount({
        ...searchData,
      }).then((res) => {
        this.orderData = res.data;
      });
    },
    onLoad() {
      const searchData = {
        orderCode: this.searchData.orderCode,
        regionName: this.searchData.regionName,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));

      this.showLoading = true;

      financeList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
      this.financeCount();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        createTime: "",
        orderCode: "",
        regionName: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
      this.financeCount();
    },

    // 区域详情
    handleDetails(row) {
      this.$router.push({
        path: "/order/manage/detail",
        query: {
          id: row.id,
        },
      });
    },

    // 导出
    handleExport() {
      const searchData = {
        orderCode: this.searchData.orderCode,
        regionName: this.searchData.regionName,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/region/finance/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
.order-box {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 20px;
  margin-bottom: 20px;
}
.order-box-list {
  width: 16.5%;
  text-align: center;
}
.order-box-title {
  font-size: 45px;
  font-weight: 400;
  height: 70px;
}
.order-box-info {
  color: #999;
}
</style>